import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchProviderAreas(page = 1, filters) {
  return $http
    .get(`/admin/distributerSectionArea`, {
      params: { page, filter: filters },
      headers: authHeader(),
    })
    .then((res) => {
      let fetchedData = res.data.data;
      return {
        providerAreasList: fetchedData.result,
        pagination: {
          totalPages: fetchedData.pages,
          totalResultsCount: fetchedData.count,
          nextPage: fetchedData.next,
        },
      };
    });
}
function editProviderAreas(entityId, areaId, body) {
  return $http.patch(
    `/admin/distributerSectionArea`,
    { entityId, areaId, ...body },
    {
      headers: authHeader(),
    }
  );
}

function importProviderSectionAreaSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/entitySectionArea/import?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}
function exportProviderSectionAreaSheet(action, entityId) {
  let paramsObject = { action };
  if (entityId) {
    paramsObject.filters = {
      entityId,
    };
  }
  return $http.get(`/admin/entitySectionArea/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: { ...paramsObject },
  });
}
export const providerAreaServices = {
  fetchProviderAreas,
  editProviderAreas,
  importProviderSectionAreaSheet,
  exportProviderSectionAreaSheet,
};
