<template>
  <el-dialog
    title="Delivery Date Details"
    :visible.sync="visible"
    width="50%"
    @close="$emit('deliveryDate-dialog-close')"
  >
    <el-alert
      class="customAlert"
      :closable="false"
      :title="checkDays()"
      type="warning"
      show-icon
    >
    </el-alert>
    <el-form
      ref="deliveryDateForm"
      :model="value"
      :rules="deliverDateRules"
      label-width="auto"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="Delivery Date Last Update">
            <el-input
              disabled
              v-model="value.ops_delivery_time_updated_at"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label=" Delivery Date">
            <el-input
              disabled
              v-model="value.calculated_delivery_time"
              type="number"
            >
              <template slot="append">Days</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Manual Delivery Date"
            ref="ops_delivery_time"
            prop="ops_delivery_time"
          >
            <el-input
              type="number"
              :value="value.ops_delivery_time"
              @input="$emit('input', { ...value, ops_delivery_time: $event })"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="$emit('deliveryDate-dialog-close')">{{
        $t("GlobalsCancelBtn")
      }}</el-button>
      <el-button type="primary" @click="saveDeliveryDate">
        {{ $t("GlobalsSaveBtn") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: { type: Boolean, required: true },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    // positive validation for manual dilvery time 
    const mustBeginPos = (rule, value, callback) => {
      if (+value < 0) {
        callback(new Error("Delivery Date Could not a Negative Number!"));
      } else callback();
    };
    return {
      warningMsg: "",
      ops_delivery_time: this.value.ops_delivery_time,
      deliverDateRules: {
        ops_delivery_time: [{ validator: mustBeginPos, trigger: "change" }],
      },
    };
  },
  methods: {
    // check the user input so could generate the hint
    checkDays() {
      let days = this.value?.ops_delivery_time;
      let calculatedDeliveryTime = this.value?.calculated_delivery_time;
      // if manual dilvery time is empty 
      if (calculatedDeliveryTime && !days) {
        return (this.warningMsg = `Calculated Delivery Time Applied`);
      } 
      // if manual delivery time is 0 tell user that we will deliver in the same day 
      else if (+days === 0 && days !== "") {
        return (this.warningMsg = "Delivery On The Same Day Will Applied");
      } 
      // manual dilvery time applied 
      else if (
        (+days && calculatedDeliveryTime) ||
        (+days && !calculatedDeliveryTime)
      ) {
        return (this.warningMsg = "Manual Devilery Date Will Applied.");
      }
    },
    saveDeliveryDate() {
      // validate fields
      this.$refs["deliveryDateForm"].validate((valid) => {
        if (valid) {
          this.$emit("deliveryDate-dialog-confirmed");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.customAlert {
  margin-bottom: 20px;
}
</style>
