<script>
export default {
  props: {
    visible: { type: Boolean, required: true },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      badgeRules: {
        text: [
          {
            required: true,
            trigger: "change",
            message: "Please enter the badge name",
          },
        ],
        color: [
          {
            required: true,
            trigger: "input",
            message: "please select a color",
          },
        ],
      },
    };
  },
  methods: {
    saveBadge() {
      this.$refs["badgeForm"].validate((valid) => {
        if (valid) {
          this.$emit("badge-dialog-confirmed");
        }
      });
    },
  },
};
</script>
<template>
  <el-dialog
    title="Handle Premium badge"
    :visible.sync="visible"
    width="50%"
    @close="$emit('badge-dialog-close')"
  >
    <el-form
      ref="badgeForm"
      :model="value"
      :rules="badgeRules"
      label-width="120px"
    >
      <el-form-item label="" size="normal">
        <el-switch
          :value="value.isActive"
          @input="$emit('input', { ...value, isActive: $event })"
          @change="$emit('change', { ...value, isActive: $event })"
          :active-value="true"
          :inactive-value="false"
          :active-text="'On'"
          :inactive-text="'Off'"
        >
        </el-switch>
      </el-form-item>
      <el-form-item prop="text" label="Badge Name" size="normal">
        <el-input
          :value="value.text"
          @input="$emit('input', { ...value, text: $event })"
          placeholder="Name"
          size="normal"
          :disabled="!value.isActive"
          clearable
          @change="$emit('change', { ...value, text: $event })"
        ></el-input>
      </el-form-item>
      <el-form-item prop="color" label="Pick a color" size="normal">
        <el-color-picker
          :value="value.color"
          :disabled="!value.isActive"
          @input="$emit('input', { ...value, color: $event })"
          @change="$emit('change', { ...value, color: $event })"
        ></el-color-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="$emit('badge-dialog-close')">{{
        $t("GlobalsCancelBtn")
      }}</el-button>
      <el-button type="primary" @click="saveBadge">
        {{ $t("GlobalsSaveBtn") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<style></style>
