<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- filters area -->
    <!-- ************************************************************************************ -->
    <MetricFilterBox
      :defaultFilterData="metrics.states"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      :clearBtnShowen="metricsList.length > 0"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            :meta="metric.meta ? metric.meta : null"
            @change="filtersChanged(metric.key, $event)"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>
    <!-- ************************************************************************************ -->
    <!-- Table Area -->
    <!-- ************************************************************************************ -->
    <template v-if="showTableFlag">
      <el-table :data="providerAreasList" border stripe>
        <el-table-column
          prop="id"
          :label="$t('providerAreaPageTableColumnId')"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('providerAreaPageTableColumnName')"
        >
        </el-table-column>
        <el-table-column
          prop="area"
          :label="$t('providerAreaPageTableColumnArea')"
        >
          <template slot-scope="props">
            <div>
              {{ getAreaNameById(props.row.areaId) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="minOrderAmount"
          :label="$t('providerAreaPageTableColumnMinOrder')"
        >
        </el-table-column>
        <el-table-column
          prop="crowdRating"
          :label="$t('ProviderAreaTableColumnCrowdRating')"
        >
          <template slot-scope="scope">
            {{ scope.row.crowdRating.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="rating"
          :label="$t('ProviderAreaPageTableColumnFaturaRating')"
        >
          <template slot-scope="scope">
            <el-rate
              v-model="scope.row.rating"
              :allow-half="true"
              @change="updateFaturaRate(scope.row)"
            ></el-rate>
          </template>
        </el-table-column>
        <el-table-column label="Delivery Time" width="auto">
          <template slot-scope="scope">
            <div class="deliverTime__wrapper">
              <el-button
                type="primary"
                icon="el-icon-info"
                size="small"
                @click="deliveryDateDetails(scope.row)"
              >
                Details
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="rating"
          :label="$t('ProviderAreaPageTableColumnOperations')"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="openBadgeDialog(scope.row)"
            >
              {{ $t('ProviderAreaPageHandlePremiumBadgeBtn') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <div class="providerAreaPage__no-selection-message u-font--super">
        {{ $t('providerAreaPageNoAreaSelectedMsg') }}
      </div>
    </template>
    <!-- ************************************************************************************ -->
    <!-- Pagination area -->
    <!-- ************************************************************************************ -->
    <div
      class="u-display-flex u-justify-content--center u-top-padding--2x"
      v-if="showTableFlag"
    >
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="25"
        @current-change="pageChanged"
      ></el-pagination>
    </div>
    <!-- ************************************************************************************  -->
    <!-- Badge Dialog Area -->
    <!-- ************************************************************************************ -->
    <badge-dialog
      :visible="badgeDialogShown"
      v-model="badgeData"
      @badge-dialog-close="clearBadgeDialog"
      @badge-dialog-confirmed="saveBadge"
    />
    <!-- Devliery Date Details Dialog -->

    <DeliverDateDialog
      :visible="deliveryDateDialogShown"
      v-model="deliveryDateData"
      @deliveryDate-dialog-close="clearDeliveryDateDialog"
      @deliveryDate-dialog-confirmed="saveDeliveryDate"
    />
    <!--/Devliery Date Details Dialog -->
  </div>
</template>

<script>
import { providerAreaServices } from '../../../services/providerArea.service';
import _ from 'lodash';
import MetricFilterBox from '@/components/transaction/MetricFilterBox';
import metricbox from '@/components/transaction/metricbox';
import { providerAreaMetrics } from '@/components/transaction/metricboxComponents';
import BadgeDialog from './BadgeDialog.vue';
import DeliverDateDialog from './DeliverDateDialog.vue';
export default {
  name: 'ProviderManagementPage',
  components: {
    MetricFilterBox,
    metricbox,
    ...providerAreaMetrics,
    BadgeDialog,
    DeliverDateDialog,
  },
  data() {
    return {
      providerAreasList: [],
      pagination: {},
      filters: {},
      metricsList: [],
      metrics: {
        states: [
          {
            key: 'area',
            label: 'providerAreaPageFilterLabelArea',
            compName: 'singleAreaMetric',
            meta: {
              placeholder: this.$t('providerAreaPageAreaFilterPlaceHolder'),
            },
          },
          // {
          //     key: "region",
          //     label: "providerAreaPageFilterLabelRegion",
          //     compName: "regionMetric",
          // },
          {
            key: 'name',
            label: 'providerAreaPageFilterLabelName',
            compName: 'stringMetric',
          },
          {
            key: 'entityId',
            label: 'providerAreaPageFilterLabelId',
            compName: 'stringMetric',
          },
        ],
      },
      badgeDialogShown: false,
      deliveryDateDialogShown: false,
      badgeData: {},
      deliveryDateData: {},
      selectedData: {},
    };
  },
  methods: {
    getProviderArea(page = 1, chosenFilters) {
      const filters = chosenFilters;
      this.$loading();
      providerAreaServices
        .fetchProviderAreas(page, filters)
        .then((res) => {
          this.providerAreasList = res.providerAreasList;
          this.pagination = res.pagination;
        })
        .catch((err) => {
          console.log('error :>> ', err);
          this.providerAreasList = [];
          this.pagination = {};
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    filtersChanged(key, value) {
      if (!(typeof value === 'number') && _.isEmpty(value)) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem('filter'));
      sessionFilter.providerArea = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem('filter', JSON.stringify(sessionFilter));
      if (this.$route.fullPath != '/entities/provider-area?page=1') {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getProviderArea(this.currentPage, { ...this.filters });
    },
    updateFaturaRate(row) {
      this.$confirm(
        this.$t('ProviderAreaPageEditFaturaRatingConfirmMessage', [row.name]),
        this.$t('ProviderAreaPageEditFaturaRatingConfirmTitle'),
        { type: 'Warning' }
      )
        .then(async () => {
          try {
            await providerAreaServices.editProviderAreas(row.id, row.areaId, {
              rating: row.rating,
            });
            this.getProviderArea(this.currentPage, this.filters);
          } catch (err) {
            console.log(err);
          }
        })
        .catch(() => {
          this.getProviderArea(this.currentPage, this.filters);
        });
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem('filter'));
      sessionFilter.providerArea = this.filters;
      sessionStorage.setItem('filter', JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem('metricsProviderArea', []);
      this.providerAreasList = [];
      this.pagination = {};
    },
    saveMetircs() {
      localStorage.setItem(
        'metricsProviderArea',
        JSON.stringify(this.metricsList)
      );
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem(
        'metricsProviderArea',
        JSON.stringify(this.metricsList)
      );
      //fix lodash with reactivity issue
      this.metricsList = [...this.metricsList];
    },
    getAreaNameById(areaId) {
      let area = _.find(this.currentAreasLookups, { key: areaId });
      let value = area && area.value ? area.value : '';
      return value;
    },
    // DeliveryDateDialog handeling//
    deliveryDateDetails(row) {
      this.selectedData = row;
      this.deliveryDateData = row.delivery_time ?? {
        ops_delivery_time_updated_at: undefined,
        calculated_delivery_time: undefined,
        ops_delivery_time: undefined,
      };
      this.deliveryDateDialogShown = true;
    },
    clearDeliveryDateDialog() {
      this.deliveryDateDialogShown = false;
    },
    async saveDeliveryDate() {
      try {
        await providerAreaServices.editProviderAreas(
          this.selectedData.id,
          this.selectedData.areaId,
          {
            ops_delivery_time:
              this.deliveryDateData.ops_delivery_time === '' || !this.deliveryDateData.ops_delivery_time
                ? null
                : +this.deliveryDateData.ops_delivery_time,
          }
        );
        this.getProviderArea(this.currentPage, this.filters);
        this.deliveryDateData = {};
        this.selectedData = {};
         this.$globalFunctions.popupMessageWrapper(
          `Delivery Time Edited Successfully`,
          "success",
          2000
        );
        this.deliveryDateDialogShown = false;
      } catch (err) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(err);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    // DeliveryDateDialog handleing//
    // ******************************************************
    // Badge Dialog Area
    // ******************************************************
    openBadgeDialog(row) {
      this.badgeData = row.meta.badge;
      this.selectedData = row;
      this.badgeDialogShown = true;
    },
    clearBadgeDialog() {
      this.badgeDialogShown = false;
    },
    async saveBadge() {
      this.$loading();
      try {
        await providerAreaServices.editProviderAreas(
          this.selectedData.id,
          this.selectedData.areaId,
          { badge: this.badgeData }
        );
        this.getProviderArea(this.currentPage, this.filters);
        this.badgeData = {};
        this.selectedData = {};
        this.badgeDialogShown = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? currentPageQuery : 1;
    },
    currentAreasLookups() {
      const currentLookups = this.$store.getters.getAllLookups;
      const currentAreas =
        currentLookups && currentLookups.areas ? currentLookups.areas : [];
      return currentAreas;
    },
    showTableFlag() {
      const currentFilters = this.filters;
      if (currentFilters) {
        if (currentFilters.area) {
          if (
            typeof currentFilters.area == 'number' ||
            currentFilters.area.length > 0
          ) {
            return true;
          }
        }
        if (currentFilters.region) {
          if (
            typeof currentFilters.region == 'number' ||
            currentFilters.region.length > 0
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    const filtersSession = JSON.parse(sessionStorage.getItem('filter'))
      .providerArea;
    if (filtersSession && filtersSession.filters)
      this.filters = { ...filtersSession.filters, area: '' };
    const metricsSession = JSON.parse(
      localStorage.getItem('metricsProviderArea')
    );
    if (metricsSession) {
      this.metricsList = metricsSession;
    }
    this.getProviderArea(this.currentPage, this.filters);
  },
  watch: {
    $route(to) {
      this.getProviderArea(to.query.page, this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.deliverTime__wrapper {
  display: flex;
  justify-content: center;
}
.providerAreaPage {
  &__no-selection-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25rem;
  }
}
</style>
